// https://s3.amazonaws.com/bizzon.online.files/website-front/assets/img/teachers/aralov_sq.jpg?region=us-east-1
const baseLink = 'https://s3.amazonaws.com/bizzon.online.files/website-front/assets/img/teachers';
// https://s3.amazonaws.com/bizzon.online.files/website-front/assets/img/experts/alexeeva_optimized.jpg
const baseAvatar = 'https://s3.amazonaws.com/bizzon.online.files/website-front/assets/img/experts';

export const teachers = [
  {
    name: 'Ольга Алексеева',
    src: null,
    avatar: `${baseAvatar}/alexeeva_optimized`,
    desc:
      'Эксперт - юрист с 20 - летним опытом правового сопровождения предприятий всех форм среднего и малого бизнеса',
  },
  {
    name: 'Дарья Алиева',
    src: null,
    avatar: `${baseAvatar}/alieva_optimized`,
    desc:
      'Руководитель Академии малого бизнеса BIZZON, предприниматель с 13 - летним стажем, член Правления «ОПОРЫ РОССИИ»',
  },
  {
    name: 'Стива Аралов',
    src: `${baseLink}/aralov_new_sq`,
    avatar: `${baseAvatar}/aralov_optimized`,
    desc:
      'Директор по развитию компании «PRIMEBEEF BAR», Экс-директор по франчайзингу компании «МОСИГРА»',
  },
  {
    name: 'Сергей Брыков',
    src: `${baseLink}/brykov_sq`,
    avatar: `${baseAvatar}/brykov_optimized`,
    desc:
      'Сооснователь компании «Киндер-Квест», совладелец Московской школы предпринимательства для детей «Поколение лидеров»',
  },
  {
    name: 'Яна Бубнова',
    src: `${baseLink}/bubnova_sq`,
    avatar: `${baseAvatar}/bubnova_optimized`,
    desc:
      'CEO коммуникационного агентства «Кабарга», ex-cmo краудлендинговой платформы Penenza.ru',
  },
  {
    name: 'Дарья Вахрушева',
    src: `${baseLink}/vahrusheva_sq`,
    avatar: `${baseAvatar}/vahrusheva_optimized`,
    desc: 'Сооснователь и управляющая агентства персонального брендинга BAKERS',
  },
  {
    name: 'Евгения Водянова',
    src: `${baseLink}/vodyanova_sq`,
    avatar: `${baseAvatar}/vodyanova_optimized`,
    desc: 'Управляющий партнер юридической фирмы The Lawyers, бизнес - юрист',
  },
  {
    name: 'Ольга Ганина',
    src: null,
    avatar: `${baseAvatar}/ganina_optimized`,
    desc:
      'Предприниматель в области коммерческих консультаций и исследований, образовательных мероприятий и тренингов',
  },
  {
    name: 'Татьяна Денисова',
    src: `${baseLink}/denisova_sq`,
    avatar: `${baseAvatar}/denisova_optimized`,
    desc:
      'Совладелец компаний «Улица бассейнов», PR-советник коммерческих и государственных организаций, политтехнолог',
  },
  {
    name: 'Павел Иванов',
    src: null,
    avatar: `${baseAvatar}/ivanov_optimized`,
    desc:
      'Управляющий партнер Paretto Finance, эксперт по финансовыми инструментам, привлечению инвестиций',
  },
  {
    name: 'Розалия Каневская',
    src: `${baseLink}/kanevskaya_sq`,
    avatar: `${baseAvatar}/kanevskaya_optimized`,
    desc:
      'Основатель и главный редактор онлайн-журнала для пиарщиков Mediabitch, владелец Rocka PR & media agency',
  },
  {
    name: 'Полина Кирова',
    src: `${baseLink}/kirova_sq`,
    avatar: `${baseAvatar}/kirova_optimized`,
    desc:
      'Директор по развитию франшизы «Рыбсеть». Член конкурсной комиссии Всемирного форума по Франчайзингу. Колумнист Forbes',
  },
  {
    name: 'Надежда Кобина',
    src: null,
    avatar: `${baseAvatar}/kobina_optimized`,
    desc:
      'Генеральный директор агентства по развитию персонального бренда «Космос - 4», эксперт по личному бренду и PR - коммуникациям',
  },
  {
    name: 'Дмитрий  Колосков',
    src: null,
    avatar: `${baseAvatar}/koloskov_optimized`,
    desc:
      'Трекер - эксперт в области упаковки и продажи бизнеса.Более 8 лет занимается инвестициями и предпринимательством',
  },
  {
    name: 'Мария Консманова',
    src: `${baseLink}/konsmanova_sq`,
    avatar: `${baseAvatar}/konsmanova_optimized`,
    desc:
      'Основатель кадрового агентства HR-PROF, вице-президент Южно-Российской гильдии HR-клубов, карьерный консультант',
  },
  {
    name: 'Маргарита Никитина',
    src: `${baseLink}/nikitina_new_sq`,
    avatar: `${baseAvatar}/nikitina_optimized`,
    desc:
      'Основатель франшизы отелей для животных BookingCat и BookingDog, экс топ-менеджер сети Х5 Retail Group, консультант',
  },
  {
    name: 'Екатерина Орлова',
    src: `${baseLink}/orlova_sq`,
    avatar: `${baseAvatar}/orlova_optimized`,
    desc:
      'Эксперт-практик по построению продаж в компаниях, директор по продажам телемедицинской компании «Доктис».',
  },
  {
    name: 'Светлана Павельчук',
    src: `${baseLink}/pavelchuk_sq`,
    avatar: `${baseAvatar}/pavelchuk_optimized`,
    desc:
      'Руководитель и основатель АвангардБюро, эксперт по финансам и налогообложению, 1500+ клиентов',
  },
  {
    name: 'Анна Родионова',
    src: null,
    avatar: `${baseAvatar}/rodionova_optimized`,
    desc: ' Эксперт по налогообложению, бухгалтерскому учету и регистрации бизнеса',
  },
  {
    name: 'Катерина Рудыка',
    src: `${baseLink}/rudyka_new_sq`,
    avatar: `${baseAvatar}/rudyka_optimized`,
    desc:
      'СЕО рекламной студии «RUDY», интернет - маркетолог, digital - стратег.',
  },
  {
    name: 'Антон Сергеев',
    src: `${baseLink}/sergeev_sq`,
    avatar: `${baseAvatar}/sergeev_optimized`,
    desc:
      'Основатель сети танцевальных школ «Дети на паркете», 142 школ в 32 городах РФ, победитель премии «Бизнес Успех» 2019г',
  },
  {
    name: 'Дмитрий Сизов',
    src: `${baseLink}/sizov_sq`,
    avatar: `${baseAvatar}/sizov_optimized`,
    desc:
      'Руководитель департамента РАФ по работе с регионами, генеральный директор сети магазинов аксессуаров для женщин SELENA',
  },
  {
    name: 'Кирилл Степико',
    src: `${baseLink}/stepiko_sq`,
    avatar: `${baseAvatar}/stepiko_optimized`,
    desc:
      'Ведущий маркетолог ТМ «33 пингвина», специалист в направлении b2b и b2c продвижения и франчайзинга',
  },
  {
    name: 'Андрей Уваров',
    src: null,
    avatar: `${baseAvatar}/uvarov_optimized`,
    desc:
      'Директор по франчайзингу BABOR, член Российской ассоциации франчайзинга, более 10 лет в системе франчайзинга',
  },
  {
    name: 'Алина Усманова',
    src: `${baseLink}/usmanova_sq`,
    avatar: `${baseAvatar}/usmanova_optimized`,
    desc:
      'Официальный представитель компании «Франчайзинг-Интеллект» в Северо-Западном округе, консалтинговой компании, которая создала более 30 франшиз.',
  },
  {
    name: 'Василь Газизулин',
    src: `${baseLink}/gazizulin_sq`,
    avatar: null,
    desc:
      'Сооснователь TopFranchise, крупнейшего портала по продвижению и продаже франшиз в России. Почетный член РАФ',
  },
  {
    name: 'Галина Харнахоева',
    src: `${baseLink}/harnahoeva_new_sq`,
    avatar: `${baseAvatar}/harnahoeva_optimized`,
    desc:
      'Сооснователь коммуникационного агентства «Кабарга», экс-топ менеджер инвестиционной платформы penenza.ru. PR-эксперт',
  },
  {
    name: 'Александр Дяченко',
    src: `${baseLink}/diachenko_sq_bw`,
    avatar: `${baseAvatar}/diachenko_sq_bw`,
    desc:
      'Digital-маркетолог, брендолог, ведущий подкаста «Маркетинг и реальность»',
  },
  {
    name: 'Елена Князева',
    src: `${baseLink}/knyazeva_sq`,
    avatar: null,
    desc: 'Эксперт по выводу продуктовых торговых марок в ритейл',
  },
  {
    name: 'Пономарев Сергей',
    src: `${baseLink}/ponomarev_sq`,
    avatar: null,
    desc:
      'Исполнительный директор Центра импакт-инвестиций «Галилео», сертифицированный тренер по социальному предпринимательству',
  },
  {
    name: 'Дмитрий Соловьев',
    src: `${baseLink}/soloviyov_sq`,
    avatar: null,
    desc: 'Руководитель налоговой практики Ventum Partners',
  },
].map((element, index) => {
  const isWebpSupported = window.localStorage.getItem('bizzon-webp');
  if (!!element.avatar) {
    if (isWebpSupported === 'true') {
      element.avatar += '.webp';
    } else {
      element.avatar += '.jpg';
    }
  }
  if (!!element.src) {
    if (isWebpSupported === 'true') {
      element.src += '.webp';
    } else {
      element.src += '.jpg';
    }
  }
  return element;
});
