import React, { useState, useEffect } from 'react'
import {Link} from 'react-router-dom'
import HeaderButtons from '../HeaderButtons/HeaderButtons'
import './MobileNav.scss';


const MobileNav = ({ isMobileToggled, onNav }) => {
  const [classStr, setClassStr] = useState('mobile-nav');

  useEffect(() => {
    console.log(isMobileToggled)
    if (isMobileToggled === false) {
      setClassStr('mobile-nav') 
    } else {
      setClassStr(' mobile-nav mobile-nav-active ')
    } 
  }, [isMobileToggled])
  
  return (
    <div className={classStr} id={'mobile-nav'}>
      <div className={'container'}>
        <div className={'uplink mobile-link'}> О нас </div>
        <Link className={'sublink'} to="/experts" onClick={onNav}>
          Эксперты
        </Link>
        <Link className={'sublink'} to="/team" onClick={onNav}>
          Команда
        </Link>
        {/* <Link className={'sublink'} to="/contacts" onClick={onNav}>
          Контакты
        </Link> */}
        <Link to="/courses" onClick={onNav}>
          Курсы
        </Link>
        <Link to="/events" onClick={onNav}>
          Мероприятия
        </Link>
        <Link to="/partners" onClick={onNav}>
          Партнеры
        </Link>

        <div className={'header-buttons'}>
          <HeaderButtons />
        </div>
      </div>
    </div>
  );
}

export default MobileNav;
