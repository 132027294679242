import * as actionTypes from '../actions/types';


const initialState = {
  currentModal: null,
  modalData: null,
}
export const modalServiceReducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.SET_CURRENT_MODAL: 
      return {
        ...state,
        currentModal: action.payload.currentModal,
      }
    case actionTypes.SET_MODAL_DATA: {
      return {
        ...state,
        modalData: action.payload.modalData
      }
    }
    default:
      return state;
  }
}