import React, { useEffect } from 'react'
import EventPageTitle from '../../UI/EventPageTitle/EventPageTitle';
import { webinars } from '../../../configs/coursesConfig';
import WebinarCard from '../../UI/Card/WebinarCard/WebinarCard';
import { sortEvents } from '../../../_services/common';
import EventCard from '../../UI/Card/Card';

const EventsPage = () => {
  const sortedEvents = sortEvents(webinars);
  useEffect(() => {
    document.title = 'Мероприятия BIZZON';
  }, [])
  
  return(
    <div>
      <section className="courses" style={{paddingTop: '4rem'}}>
        <div className="container">
          <EventPageTitle 
            title={'Мероприятия Bizzon'}
            link={'courses'}
            secondTitle={'Курсы'}
            badgeColor={'green'}
          />
          <div className="cards">
            {sortedEvents.map((webinar, index) => (
              <EventCard  cardName='webinar' data={webinar} key={index}/>
            ))}
          </div>
        </div>
      </section>
    </div>
  )
}

export default EventsPage;