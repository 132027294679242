import React from 'react'

const Teacher = ({name, src, description}) => {
  const imageSource = require.context('../../../../assets/', true);
  let image;
  if (src.includes('https')) {
    image = src;
  } else {
    image = imageSource('./' + src);
  }

  return (
    <div className="teacher-updated">
      <div className="teacher-card">
        {/* <LazyLoadImage 
          src={image}
          alt={name}
        /> */}
        <img src={image} alt={name}/>
        <div className="teacher-description">
          <h4 className="name title-md small" style={{ marginTop: '0' }} >
            { name }
          </h4>
          <p>
            { description }
          </p>

        </div>
      </div>
    </div>
  )
}

export default Teacher;
