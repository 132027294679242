import React, {useState} from 'react'
import axios from 'axios';
import { parseJwt } from '../../../_services/common';
import {useDispatch} from 'react-redux';
import {setCurrentModal, setModalData} from '../../../actions/modalActions';
import { setIsLogged, setLogin } from '../../../actions/authActions';

const LoginModal = () => {
  const [loginVal, setLoginVal] = useState('');
  const [password, setPassword] = useState('');
  const [isError, setIsError] = useState('');
  const dispatch = useDispatch();
  
  const login = () => {
    if (!!loginVal && !!password) {
      const postData = {
        login: loginVal,
        pass: password
      }

      axios.post('https://api.bizzon.online/v2/login', postData).then(function (res) {
        const tokenNew = res.headers.authorization
        if (tokenNew) localStorage.setItem('token', tokenNew)

        const userData = JSON.parse(parseJwt(tokenNew).CustomUserInfo);
        localStorage.setItem('login', userData.Login)
        dispatch(setIsLogged(true));
        dispatch(setLogin(localStorage.getItem('login')));
        dispatch(setCurrentModal(null))
      }).catch(function (err) {
        console.log(err)
        setIsError('Введенные данные неверны')
        dispatch(setModalData('Введенные данные неверны'))
      })
    
    }
  }

  const closeModal = (event) => {
    const isBlocker = event.target.classList[event.target.classList.length -1];
    if (isBlocker === 'blocker') {
      dispatch(setCurrentModal(null));
    } else return
  }
  
  return (
    <div className="modal-wrapper">
      <div className="modal-overlay blocker" onClick={(event) => closeModal(event)}>
        <div className="modal">
          <div className="close-modal pointer" onClick={() => dispatch(setCurrentModal(null))}></div>
          <div className="modal-content">
            <div className="modal-header">
              <h5 
                className="title-md middle"
              > Войти </h5>
              <div className="text-md middle bold modal-reg pointer"
                onClick={() => dispatch(setCurrentModal('signupModal'))}
              > Регистрация  </div>
            </div>
            <div className="modal-form">
              <div className="form-item">
                <input 
                  type="login" 
                  value={loginVal} 
                  onChange={(event) => { 
                    setLoginVal(event.target.value) 
                  }} 
                  placeholder={'Логин'}
                />
              </div>
              <div className="form-item">
                <input 
                  type="password" 
                  value={password}
                  onChange={(event) => { setPassword(event.target.value) }} 
                  placeholder={'Пароль'}
                />
              </div>
              <p className="error">
                {isError}
              </p>
              <div className="modal-buttons">
                <button 
                  id="loginModalButton" 
                  className="btn-md small green border"
                  onClick={() => login()}>
                     Войти 
                </button>
                <div 
                  className="forgot text-md small"
                  onClick={() => dispatch(setCurrentModal('forgetModal'))}
                > Забыли пароль? </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginModal;
