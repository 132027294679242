import React from 'react'
import './TeamPage.scss'

const Teammate = ({image, title, desc}) => {
  // const imageSource = require.context('../../../assets/', true);
  // const src = imageSource('./' + image);
  return (
    <div className="col-lg-6">
      <div className="teammate">
        <img src={image} alt="Teammate"/>
        <div className="info">
          <h5> { title } </h5>
          <h6> { desc } </h6>
        </div>
      </div>
    </div>
  )
}

export default Teammate;
