import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { setCurrentModal, setModalData } from '../../../actions/modalActions';
import axios from 'axios'


const SignupModal = () => {
  const [isPolicy, setIsPolicy] = useState(true);
  const [isNews, setIsNews] = useState(true);
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [first_name, setFirst_name] = useState('');
  const [last_name, setLast_name] = useState('');
  const [phone, setPhone] = useState('');
  const dispatch = useDispatch();
  
  const closeModal = (event) => {
    const isBlocker = event.target.classList[event.target.classList.length - 1];
    if (isBlocker === 'blocker') {
      dispatch(setCurrentModal(null));
    } else return
  }

  const signUp = () => {
    if (!!email && !!pass && !!phone && !!first_name && !!last_name && isPolicy && isNews) {
      const postData = {
        email,
        pass,
        phone,
        first_name,
        last_name
      }
      
      axios.post('https://api.bizzon.online/v3/auth/register', postData).then(function (res) {
        dispatch(setModalData(email));
        dispatch(setCurrentModal('successModal'));
      }).catch(function (err) {
        console.log(err)
        // $('#signupModalButton').show()
      })
    } 
      else {
      }
  }
  
  
  return (
    <div className="modal-wrapper" id={'signup-form'}>
      <div className="modal-overlay blocker" onClick={(event) => closeModal(event)}>
        <div className="modal">
          <div className="close-modal pointer" onClick={() => {
            dispatch(setCurrentModal(null));
          }}></div>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="title-md middle">Регистрация</h5>
              <div className="text-md middle bold modal-reg pointer" onClick={() => dispatch(setCurrentModal('loginModal'))} > Войти </div>
            </div>
            <div className="modal-form">
              <div className="form-item">
                <input 
                  type="text" 
                  placeholder={'Имя'}
                  name="name"  
                  value={first_name}
                  onChange={(event) => { 
                    setFirst_name(event.target.value)
                  }} />
              </div>
              <div className="form-item">
                <input 
                  type="text" 
                  placeholder={'Фамилия'} 
                  name="surname"  
                  value={last_name}
                  onChange={(event) => {
                    setLast_name(event.target.value)
                  }} 
                />
              </div>
              <div className="form-item">
                <input 
                  type="text" 
                  placeholder={'Телефон'} 
                  name="phone"  
                  value={phone}
                  onChange={(event) => {
                    setPhone(event.target.value);
                  }} 
                />
              </div>
              <div className="form-item">
                <input 
                  type="email" 
                  placeholder={'Email'} 
                  name="login"  
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value)
                  }} 
                />
              </div>
              <div className="form-item">
                <input 
                  type="password" 
                  name="password" 
                  placeholder={'Пароль'}  
                  value={pass}
                  onChange={(event) => {
                    setPass(event.target.value);
                  }} 
                />
              </div>
              <div className="modal-buttons">
                <button className={'btn-md small green border'} onClick={() => signUp()} > Регистрация </button>
              </div>
              <div className="checkboxes">
                <label
                  className={'checkbox-md'}
                >
                  Принимаю Политику Конфиденциальности и правила обработки персональных данных
                  Общества с ограниченной отвественностью «БИЗЗОН»
                  <input type="checkbox" checked={isPolicy} onChange={(event) => setIsPolicy(!isPolicy)}/>
                  <span className={'checkmark'}></span>
                </label>
                <label
                  className={'checkbox-md'}
                >
                  Я согласен на получение информационных писем
                  <input type="checkbox" checked={isNews} onChange={(event) => setIsNews(!isNews)}/>
                  <span className={'checkmark'}></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignupModal;
