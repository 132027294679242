import React from 'react'
import './WebinarMulti.scss'
import CardInfo from '../CardInfo'

// {
//            link: '',
//            image: 'img/@1x/events/live_1_cropped.jpg',
//            name: 'Как переупаковать бизнес во время кризиса, чтобы его спасти?',
//            desc: '',
//            person: 'Булавкина Людмила, \n Наиля Замашкина',
//            duty:
//              'Серийный предприниматель, бизнес-ангел, трекер. \n Спикер в области финансовых технологий, платежных решений и акселерации бизнеса',
//            badgeText: 'мероприятие',
//            badgeColor: 'blue',
//            cardTitle: 'Вебинар',
//            icons: [
//              {
//                iconClass: 'fas fa-wifi',
//                value: [<div className={'text-md small'}>Онлайн</div>],
//              },
//              {
//                iconClass: 'fas fa-calendar-alt',
//                value: [
//                  <div className={'text-md small'}> 18 апреля 12.00 по Мск</div>,
//                ],
//              },
//            ],
//            priority: 5,
//            dateString: 'April 18, 2020',
//          },

const WebinarMulti = ({ webinarMulti }) => {
  return (
    <a href={webinarMulti.link} className="bizzon-webinar-multi card">
      <div className={'badge-md ' + webinarMulti.badgeColor}>
        {webinarMulti.badgeText}
      </div>
      <div className="content">
        <div className="text">
          <div className="title-md small">
            {webinarMulti.cardTitle}
            <br />
            {webinarMulti.name}
          </div>
        </div>
        <div className="text-md small dex">
          {webinarMulti.desc}
        </div>

        <div className="content-hosts">
          <div className="host"></div>
          <div className="host"></div>
        </div>
        <CardInfo icons={webinarMulti.icons} />
      </div>
    </a>
  );
}

export default WebinarMulti;
