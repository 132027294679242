import { combineReducers } from "redux";
import {modalServiceReducer} from './modal_service_reducer';
import { authReducer } from "./authReducer";

const rootReducer = combineReducers({
  modalService: modalServiceReducer,
  auth: authReducer,
});

export default rootReducer;
