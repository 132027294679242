export const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  } catch (e) {
    console.log('Error in parseJwt() in common');
    return;
}
}

// sort by types and sort it by type priority


export const sortEvents = (events) => {
  // function config
  const todayStr = new Date().toDateString();
  const result = [];
  const today = new Date(todayStr).getTime();
  const types = new Set(events.map((event) => event.type));

  const splittedByTypes = {};
  types.forEach((type) => {
    splittedByTypes[type] = [];
  });


  // split all events by types
  events.forEach((event) => {
    splittedByTypes[event.type].push(event);
  });
  // Sort events in all types
  for (const [key, value] of Object.entries(splittedByTypes)) {
    splittedByTypes[key] = [
      ...value.sort((a, b) => {
        const firstDate = Date.parse(a.dateString);
        const secondDate = Date.parse(b.dateString);
        return firstDate > secondDate ? -1 : 1;
      }),
    ];
  }
  
  // Get all upcoming events
  for (const [key, value] of Object.entries(splittedByTypes)) {
    value.forEach((event, index) => {
      const eventDate = Date.parse(event.dateString);
      if (eventDate >= today) {
        result.push(event);
      }
    });
  }
  result.sort((a, b) => {
    const firstDate = Date.parse(a.dateString);
    const secondDate = Date.parse(b.dateString);

    return firstDate > secondDate? 1: -1;
  })

  const sortedUniqueEvents = new Set(result);
  for (const [key, value] of Object.entries(splittedByTypes)) {
    
    value.forEach(event => {
      sortedUniqueEvents.add(event)
    })
  }
  return [...sortedUniqueEvents];
}