import React from 'react';
import LoginModal from "./LoginModal"
import SignupModal from './SignupModal';
import ForgetModal from './ForgetModal';
import SuccessModal from './SuccesModal';
import ForgetSuccessModal from './ForgetSuccessModal';
import TeachModal from './TeachModal'

export const loginModal = () => {
  return <LoginModal  />
}

export const signupModal = () => {
  return <SignupModal  />
}

export const forgetModal = () => {
  return <ForgetModal />
}

export const successModal = () => {
  return <SuccessModal />
}

export const forgetSuccessModal = () => {
  return <ForgetSuccessModal />
}

export const teachModal = () => {
  return <TeachModal />
}