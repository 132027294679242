import * as actionTypes from './types'

export const setLogin = (login) => {
  return {
    type: actionTypes.SET_LOGIN,
    payload: {
      name: login
    }
  }
}

export const setIsLogged = (isLogged) => {
  return {
    type: actionTypes.SET_IS_LOGGED,
    payload: {
      isLogged
    }
  }
}