import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import {setCurrentModal} from '../../../actions/modalActions';
import axios from 'axios';
import {setModalData} from '../../../actions/modalActions';

const ForgetModal = () => {
  const [email, setemail] = useState('');
  const [newPass, setnewPass] = useState('');
  
  const dispatch = useDispatch();
  const closeModal = (event) => {
    const isBlocker = event.target.classList[event.target.classList.length - 1];
    if (isBlocker === 'blocker') {
      dispatch(setCurrentModal(null));
    } else return
  }

  const forget = () => {
    if (!!email && !!newPass) {
      const postData = {
        email,
        newPass
      }
      axios.post('https://api.bizzon.online/v3/auth/forget', postData).then(function (res) {
        dispatch(setModalData(email));
        dispatch(setCurrentModal('forgetSuccessModal'));
      }).catch(function (err) {
        console.log(err)
      })
    }
  }

  return (
    <div className="modal-wrapper">
      <div className="modal-overlay blocker" onClick={(event) => closeModal(event)} >
        <div className="modal">
          <div className="close-modal pointer" onClick={() => dispatch(setCurrentModal(null))}></div>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="title-md middle"> Смена пароля </h5>
              <div className="text-md middle bold modal-reg pointer" onClick={() => { dispatch(setCurrentModal('loginModal'))}} > Войти </div>
            </div>
            <div className="modal-form">
              <div className="form-item">
                <input 
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(event) => setemail(event.target.value)}
                />
              </div>
              <div className="form-item">
                <input 
                  type="password"
                  name="password"
                  placeholder="Новый пароль"
                  value={newPass}
                  onChange={(event) => setnewPass(event.target.value)}
                />
              </div>
              <div className="modal-buttons">
                <button className="btn-md small green border" onClick={() => forget()} > Сменить </button>
              </div>
            </div>
          </div>  
        </div>
      </div>
    </div>
  )

}

export default ForgetModal;