import React from "react";
// https://s3.amazonaws.com/bizzon.online.files/website-front/assets/img/events/akimova_cropped.jpg
const baseLink =
  "https://s3.amazonaws.com/bizzon.online.files/website-front/assets/img/events";

export const courses = [
  // {
  //   link: 'https://school.bizzon.online/ya_sam',
  //   image: `https://s3.amazonaws.com/bizzon.online.files/cdn_images/2020october/ya_sam`,
  //   name: '#ЯСАМ',
  //   desc: 'Интерактивная акселерационная программа для потенциальных самозанятых',
  //   icons: [
  //     {
  //       iconClass: 'far fa-clock',
  //       value: [<div className={'text-md small'}>14 дней</div>],
  //     },
  //     {
  //       iconClass: 'far fa-calendar-alt',
  //       value: [<div className={'text-md small'}>Апрель</div>],
  //     },
  //   ],
  //   priority: 9,
  //   dateString: 'April 12, 2021',
  // },
  // {
  //   link: 'https://school.bizzon.online/tiktok',
  //   image: `https://s3.amazonaws.com/bizzon.online.files/cdn_images/2020october/tiktok`,
  //   name: 'TikTok-ДРАЙВ',
  //   desc: 'Программа по продвижению себя и своего бизнеса в социальной сети TikTok',
  //   icons: [
  //     {
  //       iconClass: 'far fa-clock',
  //       value: [<div className={'text-md small'}>7 дней</div>],
  //     },
  //     {
  //       iconClass: 'far fa-calendar-alt',
  //       value: [<div className={'text-md small'}>Апрель</div>],
  //     },
  //   ],
  //   priority: 8,
  //   dateString: 'April 15, 2021',
  // },
  // {
  //   link: 'https://kurs.bizzon.online/biznes_online',
  //   image: `https://s3.amazonaws.com/bizzon.online.files/cdn_images/2020october/biz_online`,
  //   name: 'Бизнес в онлайн',
  //   desc: 'Акселерационная онлайн-программа по созданию, продвижению бизнеса и продажам в Интернете',
  //   icons: [
  //     {
  //       iconClass: 'far fa-clock',
  //       value: [<div className={'text-md small'}>2 месяца</div>],
  //     },
  //     {
  //       iconClass: 'far fa-calendar-alt',
  //       value: [<div className={'text-md small'}>Апрель-Май</div>],
  //     },
  //   ],
  //   priority: 7,
  //   dateString: 'April 16, 2021',
  // },
  {
    link: "https://school.bizzon.online/businessquest",
    image: `https://s3.amazonaws.com/bizzon.online.files/cdn_images/2020october/biz_quest`,
    name: "Бизнес-квест",
    desc: "Тренинг для старшеклассников и студентов ВУЗов и ССУЗов",
    icons: [
      {
        iconClass: "far fa-clock",
        value: [<div className={"text-md small"}>4 часа</div>],
      },
      {
        iconClass: "far fa-calendar-alt",
        value: [<div className={"text-md small"}>Онлайн</div>],
      },
    ],
    priority: 7,
    dateString: "April 17, 2021",
  },
  {
    link: "https://school.bizzon.online/quiz",
    image: `https://s3.amazonaws.com/bizzon.online.files/cdn_images/2020october/biz_quiz`,
    name: "Бизнес-квиз",
    desc: "Викторина для определения предрасположенности к предпринимательству и оценки типа предпринимательского мышления",
    icons: [
      {
        iconClass: "far fa-clock",
        value: [<div className={"text-md small"}>2 часа</div>],
      },
      {
        iconClass: "far fa-calendar-alt",
        value: [<div className={"text-md small"}>Онлайн</div>],
      },
    ],
    priority: 7,
    dateString: "April 18, 2021",
  },
  {
    link: "https://perm.bizzon.online/mkvideo",
    image: `https://thumb.tildacdn.com/tild3938-6664-4135-a132-336534343531/-/format/webp/12`,
    name: "Мастер-класс по видеосъемке на телефон",
    desc: "Наполнить бокалы шампанским и сделать офигенный контент для той самой социальной сети, которую нельзя называть:)",
    icons: [
      {
        iconClass: "far fa-map",
        value: [<div className={"text-md small"}>Пермская,17 арт-пространство «Среди людей»</div>],
      },
      {
        iconClass: "far fa-calendar-alt",
        value: [<div className={"text-md small"}>21 декабря 19:00</div>],
      },
    ],
    priority: 7,
    dateString: "April 19, 2021",
  },
  // {
  //   link: 'https://school.bizzon.online/rost-biznesa',
  //   image: `${baseLink}/fr-bg`,
  //   name: 'Эффективный рост бизнеса по франшизе',
  //   desc:
  //     'Как создать собственную франшизу, увеличить точки продаж и построить свою прибыльную сеть',
  //   icons: [
  //     {
  //       iconClass: 'far fa-clock',
  //       value: [<div className={'text-md small'}>7 модулей</div>],
  //     },
  //     {
  //       iconClass: 'far fa-calendar-alt',
  //       value: [<div className={'text-md small'}> Старт в любое время </div>],
  //     },
  //   ],
  //   priority: 8,
  //   dateString: 'April 15, 2010',
  // },
].map((course) => {
  if (course.isWebpSupported) {
    const isWebpSupported = window.localStorage.getItem("bizzon-webp");
    if (isWebpSupported === "true") {
      course.image += ".webp";
    } else {
      course.image += ".jpg";
    }
  } else {
    course.image += ".jpg";
  }
  return {
    ...course,
    type: "course",
  };
});

export const webinars = [
  // {
  //   link: 'https://school.bizzon.online/business-forum-kaluga',
  //   imageBaner: `https://s3.amazonaws.com/bizzon.online.files/cdn_images/2020october/2020_bizzon-website_3009_01.jpg`,
  //   name: 'Молодежный форум 2.0 Калуга',
  //   desc: 'ПРИДУМЫВАЙ! ДЕЙСТВУЙ! ЗАРАБАТЫВАЙ!',
  //   person: '',
  //   duty: '',
  //   badgeText: 'мероприятие',
  //   badgeColor: 'blue',
  //   cardTitle: 'Форум',
  //   icons: [
  //     {
  //       iconClass: 'fas fa-wifi',
  //       value: [<div className={'text-md small'}>Калуга, ул. Академика Королева, 1</div>],
  //     },
  //     {
  //       iconClass: 'fas fa-calendar-alt',
  //       value: [<div className={'text-md small'}>17 октября в 12.00 по Мск</div>],
  //     },
  //   ],
  //   priority: 5,
  //   dateString: 'October 17, 2020',
  // },
  // {
  //   link: 'https://school.bizzon.online/zfest',
  //   imageBaner: ``,
  //   name: 'Z-Fest',
  //   desc: 'Онлайн-форум для тех, кто хочет зарабатывать, используя TikTok',
  //   person: '',
  //   duty: '',
  //   badgeText: 'мероприятие',
  //   badgeColor: 'blue',
  //   cardTitle: 'Форум',
  //   icons: [
  //     {
  //       iconClass: 'fas fa-wifi',
  //       value: [<div className={'text-md small'}>Пермь</div>],
  //     },
  //     {
  //       iconClass: 'fas fa-calendar-alt',
  //       value: [<div className={'text-md small'}>15-22 ноября</div>],
  //     },
  //   ],
  //   priority: 5,
  //   dateString: 'October 17, 2020',
  // },
].map((webinar) => {
  // const isWebpSupported = window.localStorage.getItem('bizzon-webp');
  // console.log(isWebpSupported);
  // if (isWebpSupported === 'true') {
  //   webinar.image += '.webp';
  // } else {
  //   webinar.image += '.jpg';
  // }
  return {
    ...webinar,
    type: "webinar",
  };
});

export const events = [...courses, ...webinars];
