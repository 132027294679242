import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../../assets/img/_src/logo_white.png'
import './Footer.scss'
import { useDispatch } from 'react-redux'
import { setCurrentModal } from '../../../actions/modalActions'
const Footer = () => {
  const dispatch = useDispatch();

  return (
    <footer className="footer">
      <div className="container">
        <div className="leftside">
          <div className="logo">
            <Link to="/">
              <img src={Logo} alt="BIZZON" />
            </Link>
          </div>
          <div className="info">
            <a href="mailto:info@bizzon.online" className={'text-md'}>
              dekanat@bizzon.online
            </a>
          </div>
          <div className="text-md">Адрес - Москва, Ул. Профсоюзная, 76</div>
        </div>
        <div className="middleside">
          <a
            href="https://s3.amazonaws.com/bizzon.online.files/cdn_images/license.pdf?roistat_visit=109307"
            className="text-md"
          >
            Лицензия
          </a>
          <a
            href="https://s3.amazonaws.com/bizzon.online.files/cdn_images/oferta.pdf?roistat_visit=109307"
            className="text-md"
          >
            Договор оферты для физических лиц
          </a>
          <a
            href="https://s3.amazonaws.com/bizzon.online.files/files/oferta-2.pdf?roistat_visit=109307"
            className="text-md"
          >
            Договор оферты для юридических лиц
          </a>
          <a
            href="https://s3.amazonaws.com/bizzon.online.files/cdn_images/privacy.pdf?roistat_visit=109307"
            className="text-md"
          >
            Политика обработки персональных данных
          </a>
          <a
            href="https://s3.amazonaws.com/bizzon.online.files/cdn_images/rules.pdf?roistat_visit=109307"
            className="text-md"
          >
            Правила бесплатного участия
          </a>
          <a
            href="https://s3.amazonaws.com/bizzon.online.files/cdn_images/legal.pdf?roistat_visit=109307"
            className="text-md"
          >
            Информация о юридическом лице
          </a>
          <a
            href="https://s3.amazonaws.com/bizzon.online.files/cdn_images/rules.pdf?roistat_visit=109307"
            className="text-md"
          >
            Правила оказания платных образовательных услуг
          </a>
        </div>
        <div className="rightside">
          <div
            className="btn-md border small blue"
            onClick={() => dispatch(setCurrentModal('teachModal'))}
          >
            {' '}
            Хочу преподавать{' '}
          </div>
          <div className="bottomside">
            <a href="https://vk.com/bizzon.online?roistat_visit=109307" target="__blank">
              <i className="fab fa-vk"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
