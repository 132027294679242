import React from 'react'
import './ModalService.scss'
import {useSelector} from 'react-redux'

import * as Modals from './Modals'

const ModalService = () => {
  const currentModal = useSelector(state => state.modalService.currentModal);
  
  const Modal = Modals[currentModal];
  return (
    <div>
      {
        currentModal?
        <div className="modal-wrapper">
          <div className="modal-overlay">
            <Modal />
          </div>
       </div>
       :null
      }
    </div>

  )
}

export default ModalService;
