import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { setCurrentModal } from '../../../actions/modalActions';
import axios from 'axios';
import './TeachModal.scss'

const TeachModal = () => {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');


  const dispatch = useDispatch();
  const closeModal = (event) => {
    const isBlocker = event.target.classList[event.target.classList.length - 1];
    if (isBlocker === 'blocker') {
      dispatch(setCurrentModal(null));
    } else return
  }
  
  const register = () => {
    if (!!name && !!surname && !!email && !!phone) {
      const postData = {
        name,
        surname,
        email,
        phone
      }

      axios.post('https://api.bizzon.online/v2/teach', postData).then(function (res) {
        dispatch(setCurrentModal(null));
      }).catch(function (err) {
        console.log(err)
        dispatch(setCurrentModal(null));
      })
    }
  }
  
  return (
    <div className="modal-wrapper teach-modal">
      <div className="modal-overlay blocker" onClick={(event) => closeModal(event)}>
        <div className="modal">
          <div className="close-modal pointer" onClick={() => {
            dispatch(setCurrentModal(null));
          }}></div>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="title-md middle"> Хотите быть преподавателем академии BIZZON? </h5>
              <h3 className="title-md small"> Оставьте свои контакты, и мы свяжемся с вами! </h3>
            </div>
            <div className="modal-form">
              <div className="form-item">
                <input 
                  type="text"
                  name='name'
                  placeholder='Имя'
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value)
                  }}
                />
              </div>
              <div className="form-item">
                <input 
                  type="text"
                  name='surname'
                  placeholder={'Фамилия'}
                  value={surname}
                  onChange={(event) => {
                    setSurname(event.target.value)
                  }}
                />
              </div>
              <div className="form-item">
                <input 
                  type='email'
                  name='email'
                  placeholder='Email'
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
              </div>
              <div className="form-item">
                <input 
                  type="text"
                  name='phone'
                  placeholder='Телефон'
                  value={phone}
                  onChange={(event) => {
                    setPhone(event.target.value);
                  }}
                />
              </div>
              <div className="modal-buttons">
                <button className="btn-md small green border" onClick={() => register()}>
                  Регистрация
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeachModal;
