import React from 'react'
import './ExpertsPage.scss'

const Expert = ({avatar, name, desc}) => {
  // const imageSource = require.context('../../../assets/', true);
  // const src = imageSource('./' + avatar);
  return (
    <div className="col-lg-3 col-md-4 col-sm-6">
      <div className="expert">
        <img src={avatar} alt={'Expert'} className={'img'}/>
        <div className="info">
          <h5> { name } </h5>
          <p> { desc } </p>
        </div>
      </div>
    </div>
  )
}

export default Expert;