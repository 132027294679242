import React, { useEffect } from 'react'
import './TeamPage.scss'
import EventPageTitle from '../../UI/EventPageTitle/EventPageTitle'
import Teammate from './Teammage'

const TeamPage = ({team}) => {
  
  useEffect(() => {
    document.title = 'Команда BIZZON';
  },[])
  
  return (
    <div>
      <section className="team courses" style={{paddingTop: '4rem'}}>
        <div className="container">
          <EventPageTitle 
            title={'Команда Bizzon'}
          />

          <div className="row">
            {team.map((teammate, index) => (
              <Teammate 
                key={index}
                title={teammate.title}
                image={teammate.image}
                desc={teammate.desc}
              />
            ))}
          </div>
        </div>
      </section>
    </div>
  )
}

export default TeamPage;
