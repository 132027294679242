import React from 'react'
import './WebinarCard.scss'
import '../card.scss'
import CardInfo from '../CardInfo';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const WebinarCard = ({ webinar }) => {
  const imageSource = require.context('../../../../assets/', true);

  let src;
  if (webinar.image) {
    if (webinar.image.includes('https')) {
      src = webinar.image;
    } else {
      src = imageSource('./' + webinar.image);
    }
  }

  return (
    <a href={webinar.link} className={'card webinar'}>
      <div className={'badge-md ' + webinar.badgeColor} style={{ zIndex: 2 }}> {webinar.badgeText} </div>
      <div className={'content'}>
        <div className={'text'}>
          <div className={'title-md small'}>
            {webinar.cardTitle}
            <br />
            {webinar.name}
          </div>
          <div className={'text-md small dex'}>{webinar.desc}</div>
        </div>
        {src &&
          <div className={'content-person'}>
            {/* <img className={'img-course'} src={src} alt={webinar.person}></img> */}
            <LazyLoadImage src={src} alt={webinar.person} className={'img-course'} />
            <div className={'content-person-description'}>
              <div className={'title-md small'}> {webinar.person} </div>
              <div className="title-md small des"> {webinar.duty} </div>
            </div>
          </div>
        }
        {webinar.imageBaner &&
          <div className={'content-person'}>
            <LazyLoadImage src={webinar.imageBaner} height={120} />
          </div>
        }
        {!webinar.imageBaner &&
          <div style={{ height: '100px' }}>
          </div>
        }
        <CardInfo icons={webinar.icons} />
      </div>
    </a>
  );
}

export default WebinarCard;
