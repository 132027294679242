import React, { useState } from 'react'
import './Header.scss';
import Logo from '../../../assets/img/@2x/logo.png';
import MobileNav from '../MobileNav/MobileNav';
import HeaderButtons from '../HeaderButtons/HeaderButtons';
import Navigation from '../../UI/Navigation/Navigation';
import { Link } from 'react-router-dom';


const Header = () => {
  const [isMobileToggled, setIsMobileToggled] = useState(false);


  return (
    <header className="header header-courses header-small">
      <div className="container">
        <nav className={'nav'}>
          <div className={'header-logo'}>
            <Link to="/" onClick={() => setIsMobileToggled(false)}>
              <picture>
                <source srcSet={Logo} type={'image/png'} />
                <img src={Logo} alt={'Bizzon.online'} />
              </picture>
            </Link>
          </div>
          <Navigation  changeMobile={() => {setIsMobileToggled(!isMobileToggled)}} />
          <HeaderButtons />
        </nav>
      </div>
      <MobileNav onNav={() => setIsMobileToggled(!isMobileToggled)} isMobileToggled={isMobileToggled} />
    </header>
  )
}

export default Header;
