import React from 'react'
import './HeaderButtons.scss'
import { useDispatch, useSelector } from 'react-redux';
import {setCurrentModal} from '../../../actions/modalActions';
import { setIsLogged, setLogin } from '../../../actions/authActions';

const HeaderButtons = () => {
  const dispatch = useDispatch();
  const authState = useSelector(state => state.auth)
  
  const login = () => {
    if (!!authState.name) {
      window.location.href =
        'https://lk.bizzon.online/authsuccess?token=' +
        localStorage.getItem('token') +
        '&returnUrl=https%3A%2F%2Flk.bizzon.online';
    } else {
      window.location='https://lk.bizzon.online/login'
    }
  }
  
  return (
    <div className={'header-buttons'}>
      <div 
        id={'loginButton'} 
        className={'btn-md green border small'}
        onClick={() => {
          login();
        }}
      >
        {
          authState.isLogged ?
          authState.name : 'Войти'
        }
      </div>
      <div id={'loginNameButtonWrapper'}>
        {/* <div id={'loginNameButton'} className={'btn-md green border small'}></div> */}
        {
          authState.isLogged ?
            <div 
              onClick={() => {
                localStorage.removeItem('token');
                localStorage.removeItem('login');
                dispatch(setIsLogged(false))
                dispatch(setLogin(localStorage.getItem('login')))
              }}
              id={'logoutButton'} 
              className={'btn-md green micro'}
            >
              Выйти
            </div>
            : null
        }
        
      </div>
    </div>
  );
}

export default HeaderButtons;
