import React from 'react'

const CardInfo = ({ icons }) => {
  return (
    <div className="other-info">
      {icons.map((icon, index) => (
        <div className="icon-plus-md" key={index}>
          <i className={icon.iconClass}></i>
          {icon.value}
        </div>
      ))}
    </div>
  );
};
export default CardInfo;
