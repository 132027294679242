import React, { useEffect } from 'react'
import './ExpertsPage.scss'
import EventPageTitle from '../../UI/EventPageTitle/EventPageTitle'
import Expert from './Expert'

const ExpertsPage = ({experts}) => {
  
  const expertsArr = experts.filter(expert => !!expert.avatar)

  useEffect(() => {
    document.title = 'Эксперты BIZZON';
  }, [])
  
  return (
    <div>
      {/* <Header /> */}
      <section className="experts courses" style={{paddingTop: '4rem'}}>
        <div className="container">
          <EventPageTitle
            title={'Эксперты Bizzon'}
          />
          <div className="row">
            {expertsArr.map((expert, index) => (
              <Expert 
                key={index}
                name={expert.name}
                avatar={expert.avatar}
                desc={expert.desc}
              />
            ))}
          </div>
        </div>
      </section>
    </div>
  )
}

export default ExpertsPage;
