import React from 'react'
import './HomePartners.scss'
import Slider from 'react-slick';
import '../../../../Styles/slick.scss'
import {partners} from '../../../../configs/partnersConfig';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const HomePartners = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: 'progressive',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          adaptiveHeight: true,
        },
      },
    ],
  };
  const slides = [...partners()];
  const testSlides = [...partners()].map((element, index) => {
    return {
      src: element,
      height: '6rem',
    }
  });
  testSlides[testSlides.length - 1].height = '10rem';
  return (
    <section className="partners-main">
      <div className="container">
        <div className="title-md middle upper">Партнеры</div>
        {document.documentElement.offsetWidth > 500 ? (
          <Slider {...settings} className={'partners-slider'}>
            {slides.map((slide, index) => (
              <div key={index}>
                <div className="key-wrapper">
                  <div className="partner">
                    <LazyLoadImage
                      src={slide.src}
                      alt={'Slide' + index}
                      className="img-responsive"
                      effect="blur"
                    />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <Slider {...settings} className={'partners-slider'}>
            {testSlides.map((slide, index) => (
              <div key={index}>
                <div className="key-wrapper mobile-slider">
                  <div className="partner" style={{ height: slide.height }}>
                    <LazyLoadImage
                      src={slide.src.src}
                      alt={'Slide' + index}
                      className={'img-responsive'}
                    />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </section>
  );  
}

export default HomePartners;
