// https://s3.amazonaws.com/bizzon.online.files/website-front/assets/img/team
const baseLink = `https://s3.amazonaws.com/bizzon.online.files/website-front/assets/img/team`;

export const team = [
  {
    image: `${baseLink}/alieva`,
    title: 'Дарья Алиева',
    desc: 'Руководитель',
  },
  {
    image: `${baseLink}/chechil`,
    title: 'Екатерина Чечиль',
    desc: 'Директор по работе с регионами',
  },
  {
    image: `${baseLink}/ganina`,
    title: 'Ольга Ганина',
    desc: 'Контент - менеджер',
  },
  {
    image: `${baseLink}/visokova`,
    title: 'Диана Высокова',
    desc: 'Директор по спецпроектам',
  },
  {
    image: `${baseLink}/kondratova`,
    title: 'Регина Кондратова',
    desc: 'Трекер - менеджер',
  },
  {
    image: `${baseLink}/mikheeva`,
    title: 'Евгения Михеева',
    desc: '2D дизайнер',
  },
  {
    image: `${baseLink}/rogova`,
    title: 'Мария Рогова',
    desc: 'UX - дизайнер',
  },
  {
    image: `${baseLink}/ustinkin`,
    title: 'Евгений Устинкин',
    desc: 'Back - end - разработчик',
  },
  {
    image: `${baseLink}/kletskov`,
    title: 'Глеб Клецков',
    desc: 'Front - end - разработчик',
  },
  {
    image: `${baseLink}/alekseeva`,
    desc: 'Ольга Алексеева',
    title: 'Юрист',
  }
].map(teammate => {
  const isWebpSupported = !!window.localStorage.getItem('bizzon-webp');
  if (isWebpSupported === 'true') {
    teammate.image += '.webp';
  } else {
    teammate.image += '.jpg';
  }

  return teammate;
})