import React, { useEffect } from 'react';
import { courses } from '../../../configs/coursesConfig';
import CourseCard from '../../UI/Card/CourseCard/CourseCard';
import EventPageTitle from '../../UI/EventPageTitle/EventPageTitle';
import {sortEvents} from '../../../_services/common';
import EventCard from '../../UI/Card/Card';
const CoursesPage = () => {
  
  useEffect(() => {
    document.title = 'Курсы BIZZON';
  }, [])
  const sortedCourses = sortEvents(courses);

  return (
    <div>
      <section className="courses" style={{paddingTop: '4rem'}}>
        <div className="container">
          <EventPageTitle
            title={'Курсы BIZZON'}
            link={'events'}
            secondTitle={'Мероприятия'}
            badgeColor={'blue'}
          />
          <div className="cards">
            {sortedCourses.map((course, index) => (
              <EventCard  cardName='course' data={course} key={index}/>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default CoursesPage;
