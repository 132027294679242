import React from 'react';
import './App.scss';
import './index.scss'
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import Home from './components/Pages/HomePage/Home';
import EventsPage from './components/Pages/EventsPage/EventsPage';
import CoursesPage from './components/Pages/CoursesPage/CoursesPage';
import PartnersPage from './components/Pages/PartnersPage/PartnersPage';
import ExpertsPage from './components/Pages/ExpertsPage/ExpertsPage';
// import { experts } from './configs/expertsConfig';
import { team } from './configs/teamConfig'
import TeamPage from './components/Pages/TeamPage/TeamPage';
import { Header, Footer } from './components/Layout';
import ModalService from './components/Layout/ModalService/ModalService';
import ContactsPage from './components/Pages/ContactsPage/ContactsPage'
import { teachers } from './configs/teachersConfigNew'

// TODO: React.lazy && wouter && shadow dom styles
// TODO: refactor .courses css class
// TODO: refactor courses and events pages: Make it in one component
// TODO: Refactor script in root html file
// const Home = React.lazy(() => import('./components/Pages/HomePage/Home'));
// const CoursesPage = React.lazy(() => import('./components/Pages/CoursesPage/CoursesPage'));
// const EventsPage = React.lazy(() => import('./components/Pages/EventsPage/EventsPage'));
// const ExpertsPage = React.lazy(() => import('./components/Pages/ExpertsPage/ExpertsPage'));
// const PartnersPage = React.lazy(() => import('./components/Pages/PartnersPage/PartnersPage'));
// const TeamPage = React.lazy(() => import('./components/Pages/TeamPage/TeamPage'));


const App = () => {
  var hasWebP = false;
  var img = new Image();
  img.onload = function () {
    hasWebP = !!(img.height > 0 && img.width > 0);
    document.documentElement.classList.toggle('webp', true);
    window.localStorage.setItem('bizzon-webp', 'true');
  };
  img.onerror = function () {
    hasWebP = false;
    document.documentElement.classList.toggle('no-webp', true);
    window.localStorage.setItem('bizzon-webp', 'false');
  };
  img.src = 'https://www.gstatic.com/webp/gallery/1.webp';

  return (
    <div>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/courses" component={CoursesPage} />
        <Route exact path="/events" component={EventsPage} />
        <Route exact path="/partners" component={PartnersPage} />
        {/* <Route exact path="/contacts" component={ContactsPage} /> */}
        <Route exact path="/experts" component={() => <ExpertsPage experts={teachers} />} />
        <Route exact path="/team" component={() => <TeamPage team={team} />} />
        <Route path='/license' component={() => {
          window.location.href = 'https://s3.amazonaws.com/bizzon.online.files/cdn_images/license.pdf';
          return null;
        }} />
        <Route path='/oferta' component={() => {
          window.location.href = 'https://s3.amazonaws.com/bizzon.online.files/cdn_images/oferta.pdf';
          return null;
        }} />
        <Route path='/rules' component={() => {
          window.location.href = 'https://s3.amazonaws.com/bizzon.online.files/cdn_images/rules.pdf';
          return null;
        }} />
        <Route path='/privacy' component={() => {
          window.location.href = 'https://s3.amazonaws.com/bizzon.online.files/cdn_images/privacy.pdf';
          return null;
        }} />
        <Redirect from="/" to="/" />
      </Switch>
      <ModalService />
      <Footer />
    </div>
  );
}

export default App;
