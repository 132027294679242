import React  from 'react'
import './InfoItem.scss'

const InfoItem = ({badgeColor, badgeText, badgeDescription, title, text, link}) => {
  
  return (
    <a  href={link} className={'info-item--md'} > 
      <div className={'item-wrapper'} >
        <div className={'content'}>
          <div className={'badge-wrapper'}>
            <div className={'badge-md ' + badgeColor}></div>
            <span>{ badgeText }</span>
          </div>
          <p className={'badge-description'}>{ badgeDescription }</p>
          <h3 className={'title-md small'}> { title } </h3>
          <p className={'text-md small'}> { text } </p>
          {/* <img src={arrowWhite} alt={'arrow'}></img> */}
        </div>
        <i className={'fas fa-long-arrow-alt-right'}></i>
      </div>
    </a>
  )
}

export default InfoItem;
