import React from 'react'
import CourseCard from './CourseCard/CourseCard';
import WebinarCard from './WebinarCard/WebinarCard';
import WebinarMulti from './WebinarMulti/WebinarMulti';


export const course = ({data}) => {
  return <CourseCard  course={data} />
}

export const webinar = ({data}) => {
  return <WebinarCard  webinar={data} />
}

export const webinarMulti = ({data}) => {
  return <WebinarMulti  webinarMulti={data} />
}