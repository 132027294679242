import React from 'react'
import { Link } from 'react-router-dom';
import './navigation.scss';
import './linkStyles.scss';

const Navigation = ({ changeMobile }) => {
  return (
    <div>
      <nav className={'header-nav'} id={'header-nav'}>
        <div className={'dropdown'}>
          <div  className={'text-md header-link'}> О нас </div>
          <div className={'dropdown-content'}>
            <Link  to="/experts"> Эксперты  </Link>
            <Link  to="/team">  Команда  </Link>
            {/* <Link  to="/contacts"> Контакты </Link> */}
            {/* <Link  to="/about"> Академия  </Link> */}

          </div>
        </div>
        <Link to='/courses' className={'text-md header-link'} > Курсы </Link>
        <Link to='/events' className={'text-md'}> Мероприятия </Link>
        <Link to='/partners' className={'text-md'}> Партнеры </Link>
      </nav>
      <div className={'mobile-button'} id={'mobile-button'} onClick={changeMobile} >
        <i className={'fas fa-bars'}></i>
      </div>
    </div>
  )
}

export default Navigation;
