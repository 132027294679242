import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {setCurrentModal} from '../../../actions/modalActions';


const ForgetSuccessModal = () => {
  const dispatch = useDispatch();
  const email = useSelector(state => state.modalService.modalData);
  
  const closeModal = (event) => {
    const isBlocker = event.target.classList[event.target.classList.length - 1];
    if (isBlocker === 'blocker') {
      dispatch(setCurrentModal(null));
    } else return
  }
  
  return (
    <div className="modal-wrapper">
      <div className="modal-overlay blocker" onClick={(event) => closeModal(event)}>
        <div className="modal">
          <div className="close-modal pointer" onClick={() => {
            dispatch(setCurrentModal(null));
          }}></div>
          <div className="modal-content">
            <p>
              На почту
              <span> { email }  </span>
              отправлено письмо для подтверждения смены пароля.
            </p>
          </div>
        </div>
      </div>
    </div>
  )  
}

export default ForgetSuccessModal;
