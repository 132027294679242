// https://s3.amazonaws.com/bizzon.online.files/website-front/assets/img/partners/sfpsp.webp
export const partners = () => {
  const baseLink = 'https://s3.amazonaws.com/bizzon.online.files/website-front/assets/img/partners';
  const baseArray = [
    {
      src: `${baseLink}/mbm_cropped`,
      height: '5rem',
    },
    {
      src: `${baseLink}/opora_rossii`,
      height: '5rem',
    },
    {
      src: `${baseLink}/PFRP`,
      height: '5rem',
    },
    {
      src: `${baseLink}/msp`,
      height: '5rem',
    },
    {
      src: `${baseLink}/cpp`,
      height: '5rem',
    },
    {
      src: `${baseLink}/sfpsp`,
      height: '8rem',
    },
  ];
  const isWebpSupported = !!window.localStorage.getItem('bizzon-webp');

  if (isWebpSupported === 'true') {
    return baseArray.map(element => {
      const tmp = element.src + '.webp';
      return {
        src: tmp,
        height: element.height,
      };
    })
  } else {
    return baseArray.map(element => {
      const tmp = element.src + '.png';
      return {
        src: tmp,
        height: element.height,
      };
    })
  }
}