import React from 'react'
import './CourseCard.scss'
import '../card.scss'

 const CourseCard = ({course}) => {
  
  const imageSource = require.context('../../../../assets/', true);
  let src
  // const src = imageSource('./' + course.image);
  if (course.image.includes('https')) {
     src = course.image;
  } else {
    src = imageSource('./' + course.image);
  }
  
  return (
    <a  href={course.link} className={'card event-course'}>
      <div className={'badge-md green'}>мероприятие</div>
      <div className="img-course" style={{ backgroundImage: `url(${src})` }}></div>
      <div className={'content'}>
        <div className={'text'}>
          <div className={'title-md small'}> { course.name } </div>
          <div className={'text-md small des'}> {course.desc} </div>
        </div>
        <div className={'other-info'}>
          {
            course.icons.map((icon, index) => (
              <div className="icon-plus-md" key={index}>
                <i className={icon.iconClass}></i>
                {icon.value}
              </div>
            ))
          }
        </div>
      </div>
    </a>
  )
}

export default CourseCard;
