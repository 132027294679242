import React from 'react'
import Slider from 'react-slick'
import {teachers} from '../../../../configs/teachersConfigNew'
import Teacher from './Teacher'
import './HomeTeachersNew.scss'


const HomeTeachersNew = () => {
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  }
  
  return (
    <section className="teachers">
      <div className="container">
        <div className="title-md upper sectionTitle">Преподаватели</div>
        <Slider {...settings} className="teachers-slider">
          {teachers.map((teacher, index) => {
            if (!!teacher.src) {
              return (
                <Teacher
                  key={index}
                  name={teacher.name}
                  src={teacher.src}
                  description={teacher.desc}
                />
              );
            } else return null;
          })}
        </Slider>
      </div>
    </section>
  );
}

export default HomeTeachersNew;
