import React, { useEffect } from 'react'
import HomeHeader from './HomeHeader/HomeHeader'
import HomeCourses from './HomeCourses/HomeCourses'
import HomeTeachersNew from './HomeTeachersNew/HomeTeachersNew'
import HomePartners from './HomePartners/HomePartners'
// import HomeCourses from './HomeCourses/HomeCourses';
// const HomeCourses = React.lazy(() => import('./HomeCourses/HomeCourses'));
// const HomeTeachersNew = React.lazy(() => import('./HomeTeachersNew/HomeTeachersNew'));
// const HomePartners = React.lazy(() => import('./HomePartners/HomePartners'));
// const HomeCourses = React.lazy(() => import('./HomeCourses/HomeCourses'));
const Home = () => {
  useEffect(() => {
    document.title = 'BIZZON - Академия'
  }, [])
  
  return (
    <div className={'home'}>
      <HomeHeader />
      <HomeCourses />
      <HomeTeachersNew />
      <HomePartners />
    </div>
  );
}

export default Home;
