import React from 'react'
import { Link } from 'react-router-dom'
import './EventPageTitle.scss'

const EventPageTitle = ({title, link, secondTitle, badgeColor}) => {
  return (
    <header className="courses-header">
      <div className="courses-header-nav">
        <Link to='/' className="title-md middle upper">Главная</Link>
        <i className="fas fa-long-arrow-alt-right"></i>
        <h5 className="title-md middle upper courses-title">{ title }</h5>
      </div>
      {
        !!secondTitle?
        <Link to={'/' + link} className={'btn-md border small ' + badgeColor}>{ secondTitle }</Link>
        : null
      }
    </header>
  )
}

export default EventPageTitle;
