import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentModal } from '../../../actions/modalActions';


const SuccessModal = () => {
  const dispatch = useDispatch();
  const email = useSelector(state => state.modalService.modalData);
  
  const closeModal = (event) => {
    const isBlocker = event.target.classList[event.target.classList.length - 1];
    if (isBlocker === 'blocker') {
      dispatch(setCurrentModal(null));
    } else return
  }
  return  (
    <div className="modal-wrapper">
      <div className="modal-overlay blocker" onClick={(event) => closeModal(event)}>
        <div className="modal">
          <div className="close-modal pointer" onClick={() => {
            dispatch(setCurrentModal(null));
          }}></div>
          <div className="modal-content">
          <p> 
            Вы успешно зарегистрированы! 
            На почту <span>{ email } </span> 
            отправлено письмо для активации аккаунта.
          </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuccessModal;
