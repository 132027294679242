import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { Route, Switch, Redirect } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { createStore } from 'redux';
import {Provider} from 'react-redux';
import rootReducer from './reducers';

const store = createStore(rootReducer);
const Root = () => {
  return (
    <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path="/" component={App} />
        <Redirect exact from="/" to="/" />
      </Switch>
    </BrowserRouter>
    </Provider>
  );
}

ReactDOM.render(
  <Root></Root>,
  document.getElementById('root')
);

