import * as actionTypes from '../actions/types'

const initialState = {
  isLogged: !!localStorage.getItem('token'),
  name: localStorage.getItem('login'),
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOGIN:
      return {
        ...state,
        name: action.payload.name,
      } 
    case actionTypes.SET_IS_LOGGED: {
      return {
        ...state,
        isLogged: action.payload.isLogged,
      }
    }
    default:
      return state
  }
}

