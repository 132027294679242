import React, { useEffect, useRef } from 'react'
import './PartnersPage.scss'
import EventPageTitle from '../../UI/EventPageTitle/EventPageTitle'
import {partners} from '../../../configs/partnersConfig';
const PartnersPage = () => {
  // const imageSource = require.context('../../../assets/', true);
  // const partners = [
  //   imageSource('./img/@2x/partners/mbmru.png'),
  //   imageSource('./img/@2x/partners/opora_rossii.png'),
  //   imageSource('./img/@2x/partners/PFRP.png'),
  //   imageSource('./img/@2x/partners/msp.png'),
  //   imageSource('./img/@2x/partners/cpp.png'),
  //   imageSource('./img/@2x/partners/sfpsp.png'),
  // ]

  const isMobile = document.documentElement.offsetWidth <= 500
  const partnersRef = useRef(null);
  useEffect(() => {
    document.title = 'Партнеры BIZZON';
    // const partnersList = [...partnersRef.current.querySelectorAll('.partner')];
    // partnersList[0].style.marginBottom = '0';
    
  }, [])

  return (
    <div>
      <section className="partners courses" style={{paddingTop: '4rem'}} ref={partnersRef}>
        <div className="container">
          <EventPageTitle
            title={'Партнеры Bizzon'}
          />
          <div className=" bootstrap-wrapper row align-items-center justify-content-center">
            {partners().map((partner, key) => {
              const height = isMobile? partner.height : 'auto';
              return (
                <div className="col-md-4 col-sm-6" key={key}>
                  <div className="partner">
                    <img src={partner.src} alt={'Partner ' + key} className={'img-responsive'} style={{height}}/>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
    
  )
}

export default PartnersPage;
