import React from 'react'

import * as cards from './Cards';

const EventCard = ({cardName, data}) => {
  const Card = cards[cardName];
  return (
    <Card data={data} />
  )
}

export default EventCard;