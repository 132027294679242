import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import CourseCard from '../../../UI/Card/CourseCard/CourseCard';
import './HomeCourses.scss';
import {courses, webinars, events} from '../../../../configs/coursesConfig';
import WebinarCard from '../../../UI/Card/WebinarCard/WebinarCard';
import {sortEvents} from '../../../../_services/common';
import EventCard from '../../../UI/Card/Card';

const HomeCourses = () => {
  const [isCoursesActive, setIsCoursesActive] = useState(true);
  const [isEventsActive, setIsEventsActive] = useState(true);
  const sortedEvents = sortEvents(events);
  const [cards, setCards] = useState([...sortedEvents].splice(0, 6));

  return (
    <section className={'courses'}>
      <div className={'container'}>
        <header className={'courses-header'}>
          <div className={'tabs'}>
            <div className={'tab '}>
              <div 
                className={
                  `tab-link title-md middle upper 
                    ${isCoursesActive && isEventsActive? 'active': ''}
                  ` 
                } 
                onClick={() => {
                  setIsCoursesActive(true);
                  setIsEventsActive(true);
                  setCards([...sortedEvents].splice(0, 6));
                }}
                
              > Все </div>
            </div>
            <div className={'tab'}>
              <div
                className={
                  `tab-link title-md middle upper 
                    ${isCoursesActive && isEventsActive === false ? 'active' : ''}
                  `
                } 
                onClick={() => {
                  setIsCoursesActive(true);
                  setIsEventsActive(false);
                  setCards([...sortedEvents].filter(event => event.type === 'course'))
                }}
              
              > Курсы </div>
            </div>
            <div className={'tab'}>
              <div  
                className={
                  `tab-link title-md middle upper 
                    ${isCoursesActive === false && isEventsActive ? 'active' : ''}
                  `
                }
                onClick={() => {
                  setIsCoursesActive(false)
                  setIsEventsActive(true);
                  setCards([...sortedEvents].filter(event => event.type === 'webinar'))
                }}
              > Мероприятия </div>
            </div>
          </div>
          <div className={'courses-buttons'}>
            <Link to='/courses' className={'btn-md green border small'} > Все курсы </Link>
            <Link to='/events' className={'btn-md blue border small'} > Все мероприятия </Link>
          </div>
        </header>
          <div className="cards">
            {cards.map((card, index) => (
              <EventCard  cardName={card.type} data={card} key={index}/>
            ))}
          </div>
      </div>
    </section>
  );
}
export default HomeCourses;
