import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./HomeHeader.scss";
import MobileNav from "../../../Layout/MobileNav/MobileNav";
import InfoItem from "../InfoItem/InfoItem";

const HomeHeader = () => {
  const setWrapperHeight = () => {
    const header = document.querySelector(".main-header");
    const headerWrapper = document.querySelector(".header-wrapper");
    if (!!headerWrapper) {
      if (window.screen.width <= 768) {
        headerWrapper.style.height = `${header.offsetHeight * 1.1}px`;
      } else {
        headerWrapper.style.height = `${header.offsetHeight}px`;
      }
    }
  };
  useEffect(() => {
    setWrapperHeight();

    window.addEventListener("resize", setWrapperHeight);
  }, []);

  return (
    <div>
      <section className={"header-wrapper"}></section>
      <header className={"header main-header"}>
        {/* <MobileNav /> */}
        <div className={"container main-block"}>
          <div className={"header-content"}>
            <div className={"leftside"}>
              <h1 className={"header-title"}> Академия малого бизнеса </h1>
              <h3 className={"text-md big"}>
                Делимся знаниями, <br /> которые помогают увеличить доход
              </h3>

              <div className={"buttons"}>
                <Link to="/courses" className={"btn-md border green"}>
                  {" "}
                  Курсы{" "}
                </Link>
                <Link to="/events" className={"btn-md border blue"}>
                  Мероприятия{" "}
                </Link>
              </div>
            </div>
            <div className={"rightside"}>
              <InfoItem
                link={"https://perm.bizzon.online/mkvideo"}
                badgeColor={"green"}
                badgeText={"МК"}
                badgeDescription={"21 декабря"}
                title={"Мастер-класс по видеосъемке на телефон"}
                text={
                  "Наполнить бокалы шампанским и сделать офигенный контент для той самой социальной сети, которую нельзя называть:)"
                }
              />
              <InfoItem
                link={"https://school.bizzon.online/ya_sam"}
                badgeColor={"green"}
                badgeText={"Курс"}
                badgeDescription={"Старт скоро"}
                title={"#ЯСАМ"}
                text={
                  "Интерактивная акселерационная программа для потенциальных самозанятых"
                }
              />
              <InfoItem
                link={"https://school.bizzon.online/tiktok"}
                badgeColor={"green"}
                badgeText={"Курс"}
                badgeDescription={"Старт скоро"}
                title={"TikTok-ДРАЙВ"}
                text={
                  "Программа по продвижению себя и своего бизнеса в социальной сети TikTok."
                }
              />
              <InfoItem
                link={"https://kurs.bizzon.online/biznes_online"}
                badgeColor={"green"}
                badgeText={"Курс"}
                badgeDescription={"Старт скоро"}
                title={"Бизнес в онлайн"}
                text={
                  "Акселерационная онлайн-программа по созданию, продвижению бизнеса и продажам в Интернете"
                }
              />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default HomeHeader;
